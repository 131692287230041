import React from 'react';
import { Link } from 'react-router-dom';
import { blogData } from '../const';
import LazyLoad from 'react-lazyload';
import WrapperBlogImg from 'share/wrapperBlogImg';
import catelogy01 from "assets/images/source/homepage/catelogy05.png";
import catelogy02 from "assets/images/source/homepage/catelogy02.png";
import catelogy03 from "assets/images/source/homepage/catelogy03.png";
import catelogy04 from "assets/images/source/homepage/catelogy02.png";
import catelogy05 from "assets/images/source/homepage/catelogy05.png";
import catelogy06 from "assets/images/source/homepage/catelogy06.png";
import "./styles.scss";
const BlogHomePage = () => {
    const blogData = [
        {
            id: 1,
            img: catelogy01,
            title: "test1",
            link: "",
        },
        {
            id: 2,
            img: catelogy02,
            title: "test2",
            link: "/hop-tac/huan-luyen-doanh-nghiep",
        },
        {
            id: 3,
            img: catelogy03,
            title: "test3",
            link: "/hop-tac/toi-uu-thanh-tich",
        },
        {
            id: 4,
            img: catelogy04,
            title: "test4",
            link: "/hop-tac/tu-van-tri-lieu-ca-nhan",
        },
    ];

    const StandoutNews = ({ standoutTitle, blogData }) => {
        return (
            <>
                <h1 className='mt-10'>{standoutTitle}</h1>
                <div className="separator-line">
                    <div className="separator-animated"></div>
                </div>
                <div className="blog-content-section">
                    {blogData.map((item, index) => (
                        <div
                            key={item.id}
                            className={`blog-content-item ${index === 0 ? 'blog-first-item' : 'blog-other-items'}`}
                        >
                            <img src={item.img} alt={item.title} className="blog-item-image" />
                            <div className="blog-content-info">
                                <h2>{item.title}</h2>
                                {item.link && (
                                    <a href={item.link} className="blog-see-more-button">
                                        See More
                                    </a>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    };
    const DailyNews = ({ dailyNewsTitle, blogData }) => {
        return (
            <>
                <h1 className='mt-10'>{dailyNewsTitle}</h1>
                <div className="separator-line">
                    <div className="separator-animated"></div>
                </div>
                <div className="blog-content-section">
                    {blogData.map((item, index) => (
                        <div
                            key={item.id}
                            className={`blog-content-item ${index === 0 ? 'blog-first-item' : 'blog-other-items'}`}
                        >
                            <img src={item.img} alt={item.title} className="blog-item-image" />
                            <div className="blog-content-info">
                                <h2>{item.title}</h2>
                                {item.link && (
                                    <a href={item.link} className="blog-see-more-button">
                                        See More
                                    </a>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    };
    return (
        <div className='home-page'>
            <LazyLoad offset={100}>
                <div className="services">
                    <div className="row">
                        <WrapperBlogImg
                            blogData={blogData}
                            textAlign="center"
                        />
                        <StandoutNews standoutTitle="TIN TỨC NỔI BẬT" blogData={blogData} />
                        <DailyNews dailyNewsTitle="TIN TỨC HÀNG NGÀY" blogData={blogData} />
                    </div>
                </div>
            </LazyLoad>
        </div>
    );
};

export default BlogHomePage;