import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Box } from "@mui/material";
import "./styles.scss";
import { isMobile } from "react-device-detect";
import KinabaluTop from "assets/images/course/course-online/kilimanjaroTop.jpg";
import FSPVas from "assets/images/course/course-online/vasngau.png";
import FSPPromote from "assets/images/course/course-online/fsp_promote.png";
import Snowfall from 'react-snowfall';
import YouTubePlayer from "share/youtubePlayer";
import basicTitle from "assets/images/course/course-online/basic.png";
import advanceTitle from "assets/images/course/course-online/advance.png";
import uudai from "assets/images/course/course-online/uudai.png";
import { fansipanPromoteBasic, fansipanPromoteAdvance } from "./const";
import trainghiem from "assets/images/course/course-online/trainghiem.png";
import lienhe from "assets/images/course/course-online/lienhe.png";
import chiase from "assets/images/course/course-online/chiase.png";
import FixedBottom from "../../../ClientModules/courses/courseOffline/components/fixedBottom";
import RegisterForm from "share/registerForm";
import useSiteTitle from "core/hooks/useSiteTitle";
function ConquerFansipan() {
    const [openModal, setOpenModal] = useState(false);
    useSiteTitle("title_promote_fansipan");
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const renderPopUp = () => {
        const style = {
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // borderRadius: "40px",
            boxShadow: 24,
            position: "absolute",
            display: "block",
            width: isMobile ? "90%" : "50%",
            maxHeight: "80%",
            overflow: isMobile ? "scroll" : "auto",
            "&:focus-visible": {
                outline: "0px solid #1976d2", // Add an outline for keyboard focus
                boxShadow: 12, // Example: different shadow on focus
            },
        };
        return (
            <Modal
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={
                    {
                        // borderRadius: "40px",
                    }
                }
            >
                <Box sx={style}>
                    <RegisterForm
                        title={"Thông tin đăng ký !"}
                        // course={props.course}
                        setOpenModal={(i) => {
                            setOpenModal(i);
                        }}
                    />
                </Box>
            </Modal>
        );
    };
    const renderMountainTitle = () => {
        return (
            <section className="comparison-section">
                <img className="mt-3" src={trainghiem} style={{ width: "40%", justifySelf: "center" }} alt="Promotions" />
                <div className="yt-container">
                    <YouTubePlayer videoId="ChNNNRxxfSs" />
                </div>
                <img className=" pt-5" src={uudai} style={{ width: "30%", justifySelf: "center" }} alt="Promotions" />
                <div className="card-row">
                    {/* General Customers Card */}
                    <div className="customer-card">
                        <img className="pb-3" src={basicTitle} style={{ width: "90%", justifySelf: "center" }} alt="Basic title" />
                        <ul className="features-list">
                            {fansipanPromoteBasic.map((item, index) => (
                                <li key={index}>• {item}</li>
                            ))}
                        </ul>
                        {/* <div className="price">5.999.999 VNĐ</div> */}
                    </div>

                    {/* VIP Customers Card */}
                    <div className="customer-card">
                        <img className="pb-3" src={advanceTitle} style={{ width: "90%", justifySelf: "center" }} alt="Advance title" />
                        <ul className="features-list">
                            {fansipanPromoteAdvance.map((item, index) => (
                                <li key={index}>• {item}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <button
                    className="mt-5 jumping-contact"
                    style={{
                        width: "30%",
                        justifySelf: "center",
                        background: "none",
                        border: "none",
                        padding: "0",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        setOpenModal(true);
                    }}
                >
                    <img src={lienhe} alt="Contact" style={{ width: "100%" }} />
                </button>
            </section>
        );
    };
    const renderSharingContainer = () => {
        return (
            <>
                <img className="mt-5" src={chiase} style={{ width: "13%", justifySelf: "center" }} alt="Sharing" />
                <div className="container">
                    <div className="row">
                        {/* Each YouTube Player in a column */}
                        <div className="col-md-4 col-sm-12 mb-4">
                            <YouTubePlayer videoId="9qugi_7oY_M" />
                        </div>
                        <div className="col-md-4 col-sm-12 mb-4">
                            <YouTubePlayer videoId="Pzpfuso28uM" />
                        </div>
                        <div className="col-md-4 col-sm-12 mb-4">
                            <YouTubePlayer videoId="zpIzaXE4Ntw" />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <div>
            <div className="parallax m-0 p-0"
                style={{
                    background: `url(${KinabaluTop})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundAttachment: 'fixed',
                    backgroundRepeat: 'no-repeat',
                    minHeight: '100vh',
                }}>
                <Snowfall style={{
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                }} />
                <div className="">
                    <div className="row align-items-center">
                        {/* Left Image */}
                        <div className="col-md-4 text-center text-md-start">
                            <img className="coach-vas img-fluid" src={FSPVas} alt="Coach Vasanth Gopalan" />
                        </div>

                        {/* Right Content */}
                        <div className="col-md-8">
                            <img className="mt-5" src={FSPPromote} style={{ width: "50%", justifySelf: "center" }} alt="promote" />
                            {renderMountainTitle()}
                        </div>
                    </div>
                </div>
                {renderSharingContainer()}
            </div>
            {renderPopUp()}
            <FixedBottom></FixedBottom>
        </div>
    )
}

export default ConquerFansipan;
