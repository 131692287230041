import { question } from "fontawesome";
import { result } from "lodash";

export const colorsData = [
    { color: '#0000FF', text: 'Khóa đã học/miễn phí' },
    { color: '#FF0000', text: 'Khóa đang chọn' },
    // { color: '#FFFF2E', text: 'Khóa theo gói' },
];
export const pyramidItemsLayout = [
    [{ label: 10, color: '#808080' }],
    [{ label: 8, color: '#808080' }, { label: 9, color: '#808080' }],
    [{ label: 7, color: '#808080' }, { label: 6, color: '#808080' }, { label: 5, color: '#808080' }],
    [{ label: 1, color: '#0000FF' }, { label: 2, color: '#808080' }, { label: 3, color: '#808080' }, { label: 4, color: '#808080' }]
];

export const comboDataDetails = [
    { defaultText: "Full trạm" },
    { defaultText: "Trạm 1: Kỹ Năng" },
    { defaultText: "Trạm 2: Tư Duy" },
    { defaultText: "Trạm 3: Về Đích" },
];

export const stationItemsLayout = [
    {
        stationNameEng: 'Skill Station',
        stationNameVie: 'Trạm Kĩ Năng',
        price: 300000,
        testDetail: 'thông tin test',
        color: '#808080'
    },
    {
        stationNameEng: 'Mindset Station',
        stationNameVie: 'Trạm Tư Duy',
        price: 300000,
        testDetail: 'thông tin test',
        color: '#808080'
    },
    {
        stationNameEng: 'Finish Station',
        stationNameVie: 'Trạm Về Đích',
        price: 300000,
        testDetail: 'thông tin test',
        color: '#808080'
    },
];

export const videoInfoAndQuestion = [
    {
        id: 0,
        title: 'Bài 1: Sức Khỏe Hoàn Hảo',
        url: 'https://player.vimeo.com/video/1037711605',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Điều gì sẽ trở thành sự thật?',
                answerchoice: [
                    'A. Lời nói giả vờ được lặp đi lặp lại vô số lần.',
                    'B. Điều bạn từng thử làm nhưng chưa thành công.',
                    'C. Không có gì là sự thật.',
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Điều quan trọng nhất trong việc có cơ thể khỏe mạnh?',
                answerchoice: [
                    'A. Tập thể dục.',
                    'B. Ăn uống đủ chất.',
                    'C. Sức khỏe tâm trí.',
                ],
                result: 2,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Sức khỏe tâm trí chiếm bao nhiêu % trong việc giữ cơ thể khỏe mạnh?',
                answerchoice: [
                    'A. 50%',
                    'B. 70%',
                    'C. 80%',
                ],
                result: 2,
            },
        ],
    },
    {
        id: 1,
        title: 'Bài 2: Các Vùng',
        url: 'https://player.vimeo.com/video/1037712841',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: “3 Vùng” được nhắc đến bao gồm:',
                answerchoice: [
                    'A. Vùng kiên trì - Vùng thành tựu - Vùng thành công.',
                    'B. Vùng an toàn (thoải mái) - Vùng lười biếng - Vùng thành công.',
                    'C. Vùng hạnh phúc - Vùng thử thách - Vùng thành công.',
                ],
                result: 1,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: 65% con nggươì chúng ta đều ở...',
                answerchoice: [
                    'A. Vùng an toàn (thoải mái)',
                    'B. Vùng kiên trì.',
                    'C. Vùng thành công.',
                ],
                result: 0,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Một hành vi giúp bản thân tiến đến Vùng thành công?',
                answerchoice: [
                    'A. Thúc đẩy bản thân thoát ra khỏi vùng an toàn.',
                    'B. Học cách trở thành người lãnh đạo.',
                    'C. Không cần làm gì cả.',
                ],
                result: 0,
            },
        ],
    },
    {
        id: 2,
        title: 'Bài 3: Môi Trường',
        url: 'https://player.vimeo.com/video/1037713910',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Có 3 môi trường, đó là:',
                answerchoice: [
                    'A. Công việc hoặc Học hành / Về nhà / Giải trí.',
                    'B. Ăn uống / Ngủ / Giải trí.',
                    'C. Cả hai đều sai.',
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: 99% chúng ta đều...',
                answerchoice: [
                    'A. Làm được mới tin tưởng.',
                    'B. Thấy trước mới tin tưởng.',
                    'C. Tin tưởng rồi mới thấy.',
                ],
                result: 2,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Điều nào là đúng?',
                answerchoice: [
                    'A. Bạn tưởng tượng những điều bạn muốn trong tâm trí trước khi bạn thực hiện nó.',
                    'B. Đừng bao giờ nghĩ trước rồi mới làm.',
                    'C. Bạn không cần cố gắng vì bạn vốn dĩ đã rất xuất sắc rồi.',
                ],
                result: 0,
            },
        ],
    },
    {
        id: 3,
        title: 'Bài 4: Bài Học Về Niềm Tin',
        url: 'https://player.vimeo.com/video/1037714995',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Niềm tin giới hạn là gi?',
                answerchoice: [
                    'A. Những niềm tin tốt nhưng chưa được kích hoạt.',
                    'B. Những niềm tin xấu đang cản trở bạn.',
                    'C. Cả hai đều đúng.',
                ],
                result: 1,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Điều gì thay đổi “Niềm tin giới hạn”?',
                answerchoice: [
                    'A. Trao quyền cho niềm tin.',
                    'B. Nới lỏng giới hạn.',
                    'C. Không gì cả.',
                ],
                result: 0,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Niềm tin quan trọng là...',
                answerchoice: [
                    'A. Tin vào những người xung quanh.',
                    'B. Tin rằng không có gì là không thể.',
                    'C. Tin rằng bạn bè là sức mạnh.',
                ],
                result: 1,
            },
        ],
    },
    {
        id: 4,
        title: 'Bài 5: Giá Trị',
        url: 'https://player.vimeo.com/video/1037715816',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Niềm tin là gì?',
                answerchoice: [
                    'A. Động lực.',
                    'B. Sức mạnh.',
                    'C. Gánh nặng.',
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Cái nào không phải giá trị tốt?',
                answerchoice: [
                    'A. Tính nhất quán.',
                    'B. Tính trung thực.',
                    'C. Tính do dự.',
                ],
                result: 2,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Giá trị cao hơn Niềm tin?',
                answerchoice: [
                    'A. Đúng.',
                    'B. Sai.',
                    'C. Có thể',
                ],
                result: 0,
            },
        ],
    },
    {
        id: 5,
        title: 'Bài 6: Giá Trị Tốt Và Xấu',
        url: 'https://player.vimeo.com/video/1037716736',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Giá trị xấu khiến bạn?',
                answerchoice: [
                    'A. Có những phần thiếu hụt.',
                    'B. Trở nên xấu xa.',
                    'C. Không có gì xấu cả.',
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Bạn cần biết gì vô giá để trở thành nền tảng thành công?',
                answerchoice: [
                    'A. Biết về người khác.',
                    'B. Biết về người dạy.',
                    'C. Biết về chính mình.',
                ],
                result: 2,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Thông qua bài học về giá trị, bạn sẽ biết được mình có thể...',
                answerchoice: [
                    'A. Trở thành thiên tài.',
                    'B. Xử lý vấn đề.',
                    'C. Cài đặt các giá trị vào ngay trong vô thức.',
                ],
                result: 2,
            },
        ],
    },
    {
        id: 6,
        title: 'Bài 7: Rapport',
        url: 'https://player.vimeo.com/video/1037718061',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Rapport là gì?',
                answerchoice: [
                    'A. “Đối xử với mọi người như cách họ mong được đối xử.”',
                    'B. “Hãy luôn yêu cuộc sống này.”',
                    'C. “Chúng ta có vô số cơ hội.”',
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Nếu bạn muốn được người khác yêu thích, bạn phải...',
                answerchoice: [
                    'A. Làm điều bạn thích.',
                    'B. Làm điều khiến họ thích bạn.',
                    'C. Cả hai đều sai.',
                ],
                result: 1,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Xây dựng MQH tốt sẽ?',
                answerchoice: [
                    'A. Vui vẻ.',
                    'B. Mang đến những cơ hội.',
                    'C. Không có gì cả.',
                ],
                result: 1,
            },
        ],
    },
    {
        id: 7,
        title: 'Bài 8: Vấn Đề Trong Mối Quan Hệ.',
        url: 'https://player.vimeo.com/video/1037720738',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Điều gì gây ra vấn đề trong các mối quan hệ?',
                answerchoice: [
                    'A. Sự giao tiếp.',
                    'B. Sự dối trá.',
                    'C. Sự giàu có.',
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: 2 lí do khiến họ nói “Không” với bạn?',
                answerchoice: [
                    'A. Họ không hiểu bạn / Họ không thích bạn.',
                    'B. Họ bận / Họ không thấy bạn có giá trị.',
                    'C. Họ cảm thấy bạn phiền / Họ có nhiều mối quan hệ khác để bận tâm..',
                ],
                result: 0,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: “Mọi người có ý nhiều hơn những gì họ nói.”',
                answerchoice: [
                    'A. Đúng.',
                    'B. Có thể.',
                    'C. Sai.',
                ],
                result: 0,
            },
        ],
    },
    {
        id: 8,
        title: 'Bài 9: Làm Chủ Mối Quan Hệ',
        url: 'https://player.vimeo.com/video/1040666252',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Nên tập trung làm chủ mối quan hệ với...',
                answerchoice: [
                    'A. gia đình.',
                    'B. chính mình',
                    'C. bạn đời.',
                ],
                result: 1,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: “Bạn phải chịu trách nhiệm 100% cho mọi thứ đang xảy ra trong cuộc sống của bạn.”',
                answerchoice: [
                    'A. Có thể.',
                    'B. Đúng.',
                    'C. Sai.',
                ],
                result: 1,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Nếu bạn muốn mọi người thể hiện mặt tốt của họ với bạn, bạn phải?',
                answerchoice: [
                    'A. Cho họ tiền.',
                    'B. Cho họ nguồn lực.',
                    'C. Cho họ thấy mặt tốt của mình.',
                ],
                result: 2,
            },
        ],
    },
    {
        id: 9,
        title: 'Bài 10: Trở Thành Một Người Tốt.',
        url: 'https://player.vimeo.com/video/1040666419',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Trở thành người tốt có nghĩa là?',
                answerchoice: [
                    'A. Mang nguồn năng lượng tốt/tích cực đến mọi người.',
                    'B. Luôn làm thiện nguyện.',
                    'C. Bạn đã đủ tốt rồi.',
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Đôi khi, vấn đề của chúng ta là...',
                answerchoice: [
                    'A. ...biết quá nhiều.',
                    'B. ...biết quá ít.',
                    'C. ...không biết gì cả.',
                ],
                result: 0,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Điều đầu tiên trong việc xây dựng mối quan hệ hoàn hảo?',
                answerchoice: [
                    'A. Yêu bản thân mình.',
                    'B. Yêu tất cả mọi người.',
                    'C. Yêu giá trị người khác mang đến.',
                ],
                result: 0,
            },
        ],
    },
    {
        id: 10,
        title: 'Trạm 1: Kỹ Năng',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Bạn cần hiểu gì về cách cơ thể hoạt động?',
                answerchoice: [
                    'A. Hãy luôn tích lũy kinh nghiệm cho bản thân.',
                    'B. Chúng ta đã có toàn bộ những nguồn lực mà chúng ta cần để thành công.',
                    'C. Hãy nghỉ ngơi cho đến khi khỏe.',
                ],
                result: 1,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Khi bạn thúc đẩy chính mình vượt ra khỏi vùng an toàn, bạn sẽ...',
                answerchoice: [
                    'A. ...liên tục nảy ra những ý tưởng mới.',
                    'B. ...trượt xuống vùng lười biếng..',
                    'C. ...cả hai đều sai.',
                ],
                result: 0,
                ////
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Bởi vì tin tưởng/niềm tin ảnh hưởng tới...',
                answerchoice: [
                    'A. ...hành động.',
                    'B. ...suy nghĩ.',
                    'C. ...trí tưởng tượng.',
                ],
                result: 2,
            },
            {
                id: 3,
                question: 'Câu hỏi 4: Thay vì nói điều..., tôi chọn nói điều...',
                answerchoice: [
                    'A. tiêu cực / tích cực.',
                    'B. nhỏ nhặt / lớn lao.',
                    'C. thú vị / nghiêm túc.',
                ],
                result: 0,
            },
        ],
    },
    {
        id: 11,
        title: 'Trạm 2: Tư Duy',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Tính dài hạn là...',
                answerchoice: [
                    'A. “Tôi không cần biết phần thưởng của mình là gì, nhưng nếu tôi chăm chí ở hiện tạim phần thưởng chắc chắn sẽ đến.”',
                    'B. “Tôi đã luôn là người giỏi nhất từ khi sinh ra.”',
                    'C. “Tôi không cần bất kì ai dạy dỗ hay hướng dẫn mình cả, bởi vì tôi vốn dĩ được sinh ra để thành công.”',
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Chọn câu sai:',
                answerchoice: [
                    'A. Có giá trị tốt và giá trị xấu.',
                    'B. Những giá trị xấu không ảnh hưởng đến hành vi con người.',
                    'C. Luôn luôn nói “CÓ.”',
                ],
                result: 1,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Điều gì là cần thiết khi xây dựng mối quan hệ?',
                answerchoice: [
                    'A. Tính linh hoạt.',
                    'B. Tiền bạc.',
                    'C. Thời gian.',
                ],
                result: 0,
            },
        ],
    },
    {
        id: 12,
        title: 'Trạm 3: Về Đích',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Điều quan trọng nhất trong việc có cơ thể khỏe mạnh?',
                answerchoice: [
                    'A. Tập thể dục.',
                    'B. Ăn uống đủ chất.',
                    'C. Sức khỏe tâm trí.',
                ],
                result: 2,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: 65% con người chúng ta đều ở...',
                answerchoice: [
                    'A. Vùng an toàn (thoải mái).',
                    'B. Vùng kiên trì.',
                    'C. Vùng thành công.',
                ],
                result: 0,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Điều nào là đúng?',
                answerchoice: [
                    'A. Bạn tưởng tượng những điều bạn muốn trong tâm trí trước khi bạn thực hiện nó.',
                    'B. Đừng bao giờ nghĩ trước rồi mới làm.',
                    'C. Bạn không cần cố gắng vì bạn vốn dĩ đã rất xuất sắc rồi.',
                ],
                result: 0,
            },
            {
                id: 3,
                question: 'Câu hỏi 4: Bạn cần hiểu gì về cách cơ thể hoạt động?',
                answerchoice: [
                    'A. Hãy luôn tích lũy kinh nghiệm cho bản thân.',
                    'B. Chúng ta đã có toàn bộ những nguồn lực mà chúng ta cần để thành công.',
                    'C. Hãy nghỉ ngơi cho đến khi khỏe.',
                ],
                result: 1,
            },
            {
                id: 4,
                question: 'Câu hỏi 5: Cái nào không phải giá trị tốt?',
                answerchoice: [
                    'A. Tính nhất quán.',
                    'B. Tính trung thực.',
                    'C. Tính do dự.',
                ],
                result: 2,
            },
            {
                id: 5,
                question: 'Câu hỏi 6: Bạn cần biết gì vô giá để trở thành nền tảng thành công?',
                answerchoice: [
                    'A. Biết về người khác.',
                    'B. Biết về người dạy.',
                    'C. Biết về chính mình.',
                ],
                result: 2,
            },
            {
                id: 6,
                question: 'Câu hỏi 7: Tính dài hạn là...',
                answerchoice: [
                    'A. “Tôi không cần biết phần thưởng của mình là gì, nhưng nếu tôi chăm chỉ ở hiện tại, phần thưởng chắc chắn sẽ đến.”',
                    'B. “Tôi đã luôn là người giỏi nhất từ khi sinh ra.”',
                    'C. “Tôi không cần bất kì ai dạy dỗ hay hướng dẫn mình cả, bởi vì tôi vốn dĩ được sinh ra để thành công.”',
                ],
                result: 0,
            },
            {
                id: 7,
                question: 'Câu hỏi 8: Nếu bạn muốn mọi người thể hiện mặt tốt của họ với bạn, bạn phải?',
                answerchoice: [
                    'A. Cho họ tiền.',
                    'B. Cho họ nguồn lực.',
                    'C. Cho họ thấy mặt tốt của mình.',
                ],
                result: 2,
            },
            {
                id: 8,
                question: 'Câu hỏi 9: Điều đầu tiên trong việc xây dựng mối quan hệ hoàn hảo?',
                answerchoice: [
                    'A. Yêu bản thân mình.',
                    'B. Yêu tất cả mọi người.',
                    'C. Yêu giá trị người khác mang đến.',
                ],
                result: 0,
            },
            {
                id: 9,
                question: 'Câu hỏi 10: Nếu bạn muốn được người khác yêu thích, bạn phải...',
                answerchoice: [
                    'A. Làm điều bạn thích.',
                    'B. Làm điều khiến họ thích bạn.',
                    'C. Cả hai đều sai.',
                ],
                result: 1,
            },
        ],
    },
];