
import React from 'react';
import KickstartImg from "assets/images/kickstartimage.png";
import KickstartBg from "assets/images/KickstartBg.png";
import "./styles.scss";

function KickStart() {
    const bodyStyle = {
        margin: 0,
        padding: 0,
        fontFamily: "Arial, sans-serif",
        background: `url(${KickstartBg}) no-repeat center center fixed`,
        backgroundSize: "cover",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    };

    return (
        <div style={bodyStyle}>
            <div className="register-container">
                <div className="image-section">
                    <img src={KickstartImg} alt="Placeholder" className="image" />
                </div>
                <div className="form-section">
                    <h2>Register</h2>
                    <form className="form-grid">
                        <input type="text" placeholder="Tên Đầy Đủ" className="form-input" />
                        <input type="email" placeholder="Email" className="form-input" />
                        <input type="password" placeholder="Số Điện Thoại" className="form-input" />
                        <input type="password" placeholder="Số Người Đi Cùng" className="form-input" />
                        <input type="tel" placeholder="Ngày Sinh" className="form-input" />
                        <input type="text" placeholder="Địa Chỉ" className="form-input" />
                        <button type="submit" className="submit-button">ĐĂNG KÝ</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default KickStart;
