import { question } from "fontawesome";
import { result } from "lodash";

export const colorsData = [
    { color: '#0000FF', text: 'Khóa đã học/miễn phí' },
    { color: '#FF0000', text: 'Khóa đang chọn' },
    // { color: '#FFFF2E', text: 'Khóa theo gói' },
];
export const pyramidItemsLayout = [
    [{ label: 10, color: '#808080' }],
    [{ label: 8, color: '#808080' }, { label: 9, color: '#808080' }],
    [{ label: 7, color: '#808080' }, { label: 6, color: '#808080' }, { label: 5, color: '#808080' }],
    [{ label: 1, color: '#0000FF' }, { label: 2, color: '#808080' }, { label: 3, color: '#808080' }, { label: 4, color: '#808080' }]
];

export const comboDataDetails = [
    { defaultText: "Full trạm" },
    { defaultText: "Trạm 1: Kỹ Năng" },
    { defaultText: "Trạm 2: Tư Duy" },
    { defaultText: "Trạm 3: Về Đích" },
];

export const stationItemsLayout = [
    {
        stationNameEng: 'Skill Station',
        stationNameVie: 'Trạm Kĩ Năng',
        price: 300000,
        testDetail: 'thông tin test',
        color: '#808080'
    },
    {
        stationNameEng: 'Mindset Station',
        stationNameVie: 'Trạm Tư Duy',
        price: 300000,
        testDetail: 'thông tin test',
        color: '#808080'
    },
    {
        stationNameEng: 'Finish Station',
        stationNameVie: 'Trạm Về Đích',
        price: 300000,
        testDetail: 'thông tin test',
        color: '#808080'
    },
];

export const videoInfoAndQuestion = [
    {
        id: 0,
        title: 'Bài 1: Mục Đích Sống',
        url: 'https://player.vimeo.com/video/1037374021',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Mục đích sống là gì?',
                answerchoice: [
                    'A. Cách mà bạn làm bánh.',
                    'B. Làm thế nào bạn phục vụ một người để họ trở nên hạnh phúc và trọn vẹn?',
                    'C. Làm thế nào để tạo ra một cuộc sống tốt đẹp hơn',
                ],
                result: 1,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: 3 ví dụ về mục đích sống là gì?',
                answerchoice: [
                    'A. Nghề nghiệp, Tài năng, Kỹ năng.',
                    'B. Hạnh phúc, Sự buồn bã, Lòng trắc ẩn.',
                    'C. Người huấn luyện, Giáo viên, Người cố vấn.',
                ],
                result: 0,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Sản phẩm có quan trọng hơn con người không?',
                answerchoice: [
                    'A. Sản phẩm là thứ quan trọng hơn cả.',
                    'B. Quy trình quan trọng hơn cả.',
                    'C. Con người luôni là thứ quan trọng nhất.',
                ],
                result: 2,
            },
        ],
    },
    {
        id: 1,
        title: 'Bài 2: Tầm Nhìn Và Dự Án Cuộc Sống',
        url: 'https://player.vimeo.com/video/1037375214',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Nếu mục đích sống là phục vụ một người để họ trở nên hạnh phúc và trọn vẹn, thì tầm nhìn là gì?',
                answerchoice: [
                    'A. Tầm nhìn là để nhìn thấy rõ ràng.',
                    'B. Tầm nhìn là câu hỏi để biết bạn đang đi đâu.',
                    'C. Tầm nhìn là phục vụ nhiều người hơn, để họ trở nên hạnh phúc và trọn vẹn, bằng cách biết chính xác bạn đang phục vụ ai.',
                ],
                result: 2,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Mục tiêu của bạn đến từ đâu?',
                answerchoice: [
                    'A.  Từ mục đích sống.',
                    'B.  Từ tầm nhìn.',
                    'C. Từ nghề nghiệp của bạn.',
                ],
                result: 1,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Bạn cần gì để phát triển?',
                answerchoice: [
                    'A. Công cụ.',
                    'B. Tầm nhìn.',
                    'C. Hệ thống.',
                ],
                result: 2,
            },
        ],
    },
    {
        id: 2,
        title: 'Bài 3: Bánh Xe Cuộc Đời',
        url: 'https://player.vimeo.com/video/1037649092',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Bánh xe cuộc đời dùng để làm gì?',
                answerchoice: [
                    'A. Cho tầm nhìn.',
                    'B. Cho mục đích sống.',
                    'C. Để tạo ra các mục tiêu rõ ràng.',
                ],
                result: 2,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Khía cạnh quan trọng nhất của bánh xe cuộc đời đối với nhân loại là gì?',
                answerchoice: [
                    'A. Sự nghiệp.',
                    'B. Gia đình.',
                    'C. Để tạo ra các mục tiêu rõ ràng.',
                ],
                result: 0,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Công việc cuối cùng của bạn là gì?',
                answerchoice: [
                    'A. Nghỉ hưu.',
                    'B. Dạy người trẻ cách thành công.',
                    'C. Kế toán.',
                ],
                result: 1,
            },
        ],
    },
    {
        id: 3,
        title: 'Bài 4: Mục Đích Của Cuộc Sống Này Là Gì?',
        url: 'https://player.vimeo.com/video/1037651327',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1:  Nếu bạn đi làm chỉ vì tiền?',
                answerchoice: [
                    'A. Có rất nhiều tiền.',
                    'B. Tiền hoàn toàn không đủ.',
                    'C. Không thỏa mãn.',
                ],
                result: 2,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Ý nghĩa thật sự của cuộc sống là gì?',
                answerchoice: [
                    'A. Không có ý nghĩa.',
                    'B. Để làm việc.',
                    'C. Để giúp đỡ.',
                ],
                result: 2,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Lợi ích của việc biết rằng chúng ta có thể phục vụ người khác là gì?',
                answerchoice: [
                    'A. Không có lợi ích gì cả.',
                    'B. Khi chúng ta phục vụ, tiền sẽ đến với chúng ta',
                    'C. Khi chúng ta phục vụ một ai đó, chúng ta nhận ra rằng điều chúng ta đang thực hiện một điều vô cùng quan trọng và ý nghĩa trong cuộc đời.',
                ],
                result: 2,
            },
        ],
    },
    {
        id: 4,
        title: 'Bài 5: Lòng Biết Ơn',
        url: 'https://player.vimeo.com/video/1037653028',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Câu nói thể hiện sự biết ơn.',
                answerchoice: [
                    'A. Tôi thích bản thân mình.',
                    'B. Tôi biết ơn vì...',
                    'C. Hãy đưa tiền cho tôi.',
                ],
                result: 1,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Hai điều bạn cần làm để luyện tập cho lòng biết ơn.',
                answerchoice: [
                    'A. Để giữ những gì bạn muốn mà có được những điều bạn muốn.',
                    'B. Để cảm thấy hạnh phúc và thỏa mãn.',
                    'C. Để trở thành người tốt.',
                ],
                result: 0,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Sau khi biết ơn vì những gì bạn muốn, bạn phải...”',
                answerchoice: [
                    'A. Thư giãn.',
                    'B. Sống trong tương lai ngay bây giờ.',
                    'C. Luôn luôn muốn điều đó.',
                ],
                result: 1,
            },
        ],
    },
    {
        id: 5,
        title: 'Bài 6: Sự Tha Thứ',
        url: 'https://player.vimeo.com/video/1037653199',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Vì sao những người thành công luôn có được điều họ mong muốn?',
                answerchoice: [
                    'A. Vì họ thông minh.',
                    'B. Họ làm sạch quá khứ của chính mình.',
                    'C. Sự may mắn.',
                ],
                result: 1,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Làm sao bạn có thể trở nên thành công nhanh chóng giống như những người thành công khác?',
                answerchoice: [
                    'A. Làm sạch những SEEs xấu để có được những SEEs tốt. Khả năng nhìn nhận và kết luận rằng tất cả những trải nghiệm xấu trong quá khứ thực ra lại là những trải nghiệm tốt.',
                    'B. Tôi không biết.',
                    'C. Nhận được sự may mắn từ việc đi Chùa cầu nguyện.',
                ],
                result: 0,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Làm thế nào để thay đổi quá khứ của bạn?',
                answerchoice: [
                    'A. Quên đi nó.',
                    'B. Không thể thay đổi.',
                    'C. Nói rằng “Hãy Tha Thứ Cho Tôi.',
                ],
                result: 2,
            },
        ],
    },
    {
        id: 6,
        title: 'Bài 7: Mối Quan Hệ',
        url: 'https://player.vimeo.com/video/1037654489',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Nguyên nhân gây ra tất cả các vấn đề trong mối quan hệ là gì?',
                answerchoice: [
                    'A. Chiến tranh.',
                    'B. Sự giao tiếp.',
                    'C. Cảm xúc.',
                ],
                result: 1,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Hai loại vấn đề trong mối quan hệ là gì?',
                answerchoice: [
                    'A. Cảm xúc và cái tôi.',
                    'B. Con trai và Con gái.',
                    'C. Bản thân và Người khác.',
                ],
                result: 2,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Tại sao chúng ta có vấn đề trong mối quan hệ với người khác?',
                answerchoice: [
                    'A. Không biết.',
                    'B. Bởi vì người khác làm tổn thương mình.',
                    'C. Bởi vì chúng ta có vấn đề mối quan hệ với chính mình. Chúng ta phản bội chính mình.',
                ],
                result: 2,
            },
        ],
    },
    {
        id: 7,
        title: 'Bài 8: Mối Quan Hệ Với Người Khác',
        url: 'https://player.vimeo.com/video/1037654811',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Trong coaching NLP, cần bao nhiêu người để một mối quan hệ hoạt động hiệu quả?',
                answerchoice: [
                    'A. Cần 2 người.',
                    'B. Chỉ cần 1 người.',
                    'C. Mối quan hệ là điều không thể.',
                ],
                result: 1,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Khi 2 người bắt đầu một mối quan hệ, điều gì cần thiết ở hiện tại?',
                answerchoice: [
                    'A. Sự khác biệt, sự thiếu kiên nhẫn, xung đột.',
                    'B. Sự tương đồng, sự khoan dung, sự kiên nhẫn.',
                    'C. Không có sự khác biệt.',
                ],
                result: 1,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Điều gì phá hủy một mối quan hệ?',
                answerchoice: [
                    'A. Sự khác biệt, sự thiếu kiên nhẫn, sự kỳ vọng.',
                    'B. Sự tiêu cực về mặt cảm xúc.',
                    'C. Sinh con.',
                ],
                result: 0,
            },
        ],
    },
    {
        id: 8,
        title: 'Bài 9: Mối Quan Hệ',
        url: 'https://player.vimeo.com/video/1037656025',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Khi chúng ta chia tay một mối quan hệ và bắt đầu một mối quan hệ mới, tại sao chúng ta lại lần nữa chia tay?',
                answerchoice: [
                    'A. Bởi vì bạn chưa hoàn toàn nhận trách nhiệm cho mối quan hệ trước đó.',
                    'B. Bạn đã có một cuộc xung đột tương tự',
                    'C. Không may mắn.',
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Tại sao chúng ta lại thu hút những người có giá trị thấp vào cuộc sống?',
                answerchoice: [
                    'A. Bởi vì bạn là một người có giá trị thấp.',
                    'B. Do bạn không may mắn.',
                    'C. Để dạy bạn một bài học.',
                ],
                result: 0,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Khi bạn chia tay, bạn có thể ngay lập tức bắt đầu một mối quan hệ khác không?',
                answerchoice: [
                    'A. Có, hãy nhanh chóng tìm một người khác.',
                    'B. Hãy hoàn toàn nhận trách nhiệm trước và nâng cấp bản thân trước khi bắt đầu một mối quan hệ mới.',
                    'C. Không.',
                ],
                result: 1,
            },
        ],
    },
    {
        id: 9,
        title: 'Bài 10: Hiệu Ứng Placebo',
        url: 'https://player.vimeo.com/video/1037656059',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Mục đích của Placebo là gì?',
                answerchoice: [
                    'A. Để tạo ra niềm tin rằng một cái gì đó có giá trị chữa bệnh và có thể chữa lành.',
                    'B. Để kích hoạt năng lượng tự chữa lành bẩm sinh của người đó.',
                    'C. Không có mục đích.',
                ],
                result: 1,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Placebo kích hoạt điều gì trong cơ thể người?',
                answerchoice: [
                    'A. Cơn sốt.',
                    'B. Hệ miễn dịch của họ.',
                    'C. Chính họ.',
                ],
                result: 1,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Chìa khóa để không bao giờ bị bệnh là gì?',
                answerchoice: [
                    'A. Kết luận tiêu cực.',
                    'B. Không biết cách nào cả.',
                    'C. Luôn luôn có những kết luận tích cực.',
                ],
                result: 2,
            },
        ],
    },
    {
        id: 10,
        title: 'Trạm 1: Kỹ Năng',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Bởi vì một khi bạn biết về mục đích sống của mình, bạn sẽ…',
                answerchoice: [
                    'A. không còn làm mọi thứ vì tiền.',
                    'B. tài năng hơn.',
                    'C. không còn căng thẳng.',
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2:  Từ tầm nhìn, chúng ta có?',
                answerchoice: [
                    'A. Mục tiêu lâu dài.',
                    'B. Định hướng tương lai.',
                    'C. Mục tiêu ngắn hạn và Mục tiêu dài hạn.',
                ],
                result: 2,
                ////
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Bánh Xe Cuộc Đời có bao nhiêu phần?',
                answerchoice: [
                    'A. 8 phần.',
                    'B. 10 phần.',
                    'C. 12 phần.',
                ],
                result: 0,
            },
            {
                id: 3,
                question: 'Câu hỏi 4: Everything…',
                answerchoice: [
                    'A. …is better',
                    'B. …goes around comes around',
                    'C. …is nothing',
                ],
                result: 1,
            },
        ],
    },
    {
        id: 11,
        title: 'Trạm 2: Tư Duy',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Nếu bạn chưa có điều bạn muốn, bạn phải?',
                answerchoice: [
                    'A. Cạnh tranh với người khác.',
                    'B. Giả vờ như thể bạn đã có nó rồi.',
                    'C. Từ bỏ.',
                ],
                result: 1,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Vì sao bạn có những SEEs đỏ và đôi khi chúng càng trở nên đỏ hơn?',
                answerchoice: [
                    'A. Bởi vì trước khi sinh ra đã có.',
                    'B. Bởi vì người khác liên tục nhắc đến.',
                    'C. Bởi vì bạn đã đưa ra kết luận xấu.',
                ],
                result: 2,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: “Nhất quán” có nghĩa như thế nào thông qua video bài học vừa rồi?',
                answerchoice: [
                    'A. Nói trước làm sau.',
                    'B. Tôi nói, tôi làm.',
                    'C. Cân nhắc hành vi trước, hành động sau.',
                ],
                result: 1,
            },
        ],
    },
    {
        id: 12,
        title: 'Trạm 3: Về Đích',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: 3 ví dụ về mục đích sống là gì?',
                answerchoice: [
                    'A. Nghề nghiệp, Tài năng, Kỹ năng.',
                    'B. Hạnh phúc, Sự buồn bã, Lòng trắc ẩn.',
                    'C. Người huấn luyện, Giáo viên, Người cố vấn.',
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Nếu mục đích sống là phục vụ một người để họ trở nên hạnh phúc và trọn vẹn, thì tầm nhìn là gì?',
                answerchoice: [
                    'A. Tầm nhìn là để nhìn thấy rõ ràng.',
                    'B. Tầm nhìn là câu hỏi để biết bạn đang đi đâu.',
                    'C. Tầm nhìn là phục vụ nhiều người hơn, để họ trở nên hạnh phúc và trọn vẹn, bằng cách biết chính xác bạn đang phục vụ ai.',
                ],
                result: 2,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Công việc cuối cùng của bạn là gì?',
                answerchoice: [
                    'A. Nghỉ hưu.',
                    'B. Dạy người trẻ cách thành công.',
                    'C. Kế toán.',
                ],
                result: 1,
            },
            {
                id: 3,
                question: 'Câu hỏi 4: Nếu bạn đi làm chỉ vì tiền?',
                answerchoice: [
                    'A. Có rất nhiều tiền.',
                    'B. Tiền hoàn toàn không đủ.',
                    'C. Không thỏa mãn.',
                ],
                ////
                result: 2,
            },
            {
                id: 4,
                question: 'Câu hỏi 5:  Hai điều bạn cần làm để luyện tập cho lòng biết ơn.',
                answerchoice: [
                    'A. Để giữ những gì bạn muốn mà có được những điều bạn muốn.',
                    'B. Để cảm thấy hạnh phúc và thỏa mãn.',
                    'C. Để trở thành người tốt.',
                ],
                result: 0,
            },
            {
                id: 5,
                question: 'Câu hỏi 6: Làm thế nào để thay đổi quá khứ của bạn?',
                answerchoice: [
                    'A. Quên đi nó.',
                    'B. Không thể thay đổi.',
                    'C. Nói rằng “Hãy Tha Thứ Cho Tôi.”',
                ],
                result: 2,
            },
            {
                id: 6,
                question: 'Câu hỏi 7: Hai loại vấn đề trong mối quan hệ là gì?',
                answerchoice: [
                    'A. Cảm xúc và cái tôi.',
                    'B. Con trai và Con gái.',
                    'C. Bản thân và Người khác.',
                ],
                result: 2,
            },
            {
                id: 7,
                question: 'Câu hỏi 8: Khi 2 người bắt đầu một mối quan hệ, điều gì cần thiết ở hiện tại?',
                answerchoice: [
                    'A. Sự khác biệt, sự thiếu kiên nhẫn, xung đột.',
                    'B. Sự tương đồng, sự khoan dung, sự kiên nhẫn.',
                    'C. Không có sự khác biệt.',
                ],
                result: 1,
            },
            {
                id: 8,
                question: 'Câu hỏi 9: Khi bạn chia tay, bạn có thể ngay lập tức bắt đầu một mối quan hệ khác không?',
                answerchoice: [
                    'A. Có, hãy nhanh chóng tìm một người khác.',
                    'B. Hãy hoàn toàn nhận trách nhiệm trước và nâng cấp bản thân trước khi bắt đầu một mối quan hệ mới.',
                    'C. Không.',
                ],
                result: 1,
            },
            {
                id: 9,
                question: 'Câu hỏi 10: Mục đích của Placebo là gì??',
                answerchoice: [
                    'A. Để tạo ra niềm tin rằng một cái gì đó có giá trị chữa bệnh và có thể chữa lành.',
                    'B. Để kích hoạt năng lượng tự chữa lành bẩm sinh của người đó.',
                    'C. Không có mục đích.',
                ],
                result: 1,
            },
        ],
    },
];