import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";
function WrapperBlogImg(props) {
    // const TitleHomeClient = (textAlign, title, description, description2) => {
    //     return (
    //         // <div
    //         //     style={{
    //         //         textAlign: textAlign,
    //         //     }}
    //         // >
    //         //     <h1 className={`title px-3`}>{title}</h1>
    //         //     {description && <p className="description-home mt-3">{description}</p>}
    //         //     {description2 && <p className="description-home">{description2}</p>}
    //         // </div>
    //     );
    // };

    return (
        <div>
            {/* {TitleHomeClient(
                "center",
                props?.title,
                props?.description,
                props?.description2
            )} */}

            <div className="wrapperBlogImg">
                {props?.blogData?.map((blogData, index) => {
                    return (
                        <Link
                            className={`gridItem${index + 1} `}
                            key={index}
                            to={blogData.link}
                        >
                            <img src={blogData.img} alt={"Error"} style={{ objectFit: "cover" }} />
                            <p className="wrapperTitle">{blogData.title}</p>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
}

export default WrapperBlogImg;
