import { question } from "fontawesome";
import { result } from "lodash";

export const colorsData = [
    { color: '#0000FF', text: 'Khóa đã học/miễn phí' },
    { color: '#FF0000', text: 'Khóa đang chọn' },
    // { color: '#FFFF2E', text: 'Khóa theo gói' },
];
export const pyramidItemsLayout = [
    [{ label: 10, color: '#808080' }],
    [{ label: 8, color: '#808080' }, { label: 9, color: '#808080' }],
    [{ label: 7, color: '#808080' }, { label: 6, color: '#808080' }, { label: 5, color: '#808080' }],
    [{ label: 1, color: '#0000FF' }, { label: 2, color: '#808080' }, { label: 3, color: '#808080' }, { label: 4, color: '#808080' }]
];

export const comboDataDetails = [
    { defaultText: "Full trạm" },
    { defaultText: "Trạm 1: Kỹ Năng" },
    { defaultText: "Trạm 2: Tư Duy" },
    { defaultText: "Trạm 3: Về Đích" },
];

export const stationItemsLayout = [
    {
        stationNameEng: 'Skill Station',
        stationNameVie: 'Trạm Kĩ Năng',
        price: 0,
        testDetail: 'thông tin test',
        color: '#808080'
    },
    {
        stationNameEng: 'Mindset Station',
        stationNameVie: 'Trạm Tư Duy',
        price: 0,
        testDetail: 'thông tin test',
        color: '#808080'
    },
    {
        stationNameEng: 'Finish Station',
        stationNameVie: 'Trạm Về Đích',
        price: 0,
        testDetail: 'thông tin test',
        color: '#808080'
    },
];

export const videoInfoAndQuestion = [
    {
        id: 0,
        title: 'Bài 1: Đỉnh Núi Thứ Hai - Kinabalu',
        url: 'https://player.vimeo.com/video/1034073953',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Nhà lãnh đạo thật sự là gì?',
                answerchoice: [
                    'A. Đối xử với mọi người đều công bằng như nhau, bất kể họ là ai.',
                    'B. Hành xử dứt khoát.',
                    'C. Giỏi kinh doanh.',
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Cách nhà lãnh đạo làm tốt việc của mình?',
                answerchoice: [
                    'A. Hãy xem xét và trải nghiệm trước, sau đó cải thiện.',
                    'B. Cải thiện vấn đề ngay lập tức, không cần xem xét.',
                    'C. Không làm gì cả.',
                ],
                result: 0,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Vì sao nhà lãnh đạo đối xử công bằng với tất cả mọi người thì sẽ khiến công ty phát triển?',
                answerchoice: [
                    'A. Bởi vì mọi người đều có động lực làm việc.',
                    'B. Bởi vì mọi người đều muốn cạnh tranh công bằng.',
                    'C. Bởi vì ai cũng muốn được khen ngợi.',
                ],
                result: 0,
            },
        ],
    },
    {
        id: 1,
        title: 'Bài 2: 5 Cảm Xúc Tiêu Cực',
        url: 'https://player.vimeo.com/video/1034074402',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: 5 cảm xúc tiêu cực mà chúng ta cần phải kiểm soát?',
                answerchoice: [
                    'A. Kích động - Hăng hái - Buồn bã - Lo lắng - Ngạc nhiên.',
                    'B. Tức giận - Buồn bã - Sợ hãi - Ghét - Tội lỗi.',
                    'C. Tuyệt vọng - Chán nản - Khinh thường - Sợ hãi - Lo âu.',
                ],
                result: 1,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Bạn không thành công vì bạn...',
                answerchoice: [
                    'A. Sợ hãi.',
                    'B. Nóng nảy.',
                    'C. Buồn bực.',
                ],
                result: 0,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Những cảm xúc đột nhiên bùng nổ vì...',
                answerchoice: [
                    'A. Do bạn kích thích.',
                    'B. Do người khác kích thích.',
                    'C. Do ký ức tồi tệ với cảm xúc tiêu cực từ quá khứ.',
                ],
                result: 2,
            },
        ],
    },
    {
        id: 2,
        title: 'Bài 3: SEE',
        url: 'https://player.vimeo.com/video/1034076425',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: SEE có SEE tốt hay SEE xấu?',
                answerchoice: [
                    'A. Tốt.',
                    'B. Xấu.',
                    'C. Cả Hai.',
                ],
                result: 2,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Một người có cảm xúc tiêu cực khiến người bên cạnh có cảm xúc tiêu cực (Ví dụ: Trong video, người cha có cảm xúc tiêu cực khiến con gái có cảm xúc tiêu cực), đó chính là...',
                answerchoice: [
                    'A. Xúc cảm lan truyền.',
                    'B. Cột mốc/Trải nghiệm xấu.',
                    'C. Đồng nhất cảm xúc.',
                ],
                result: 1,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Một người từng ở trong một mối quan hệ cũ không được tốt đẹp, để lại những SEE xấu, khi chuẩn bị bước vào một mối quan hệ mới, họ cảm thấy...',
                answerchoice: [
                    'A. Vui vẻ tiếp nhận.',
                    'B. Sợ hãi và từ chối.',
                    'C. Tùy vào mức độ tình cảm thời điểm đó.',
                ],
                result: 0,
            },
        ],
    },
    {
        id: 3,
        title: 'Bài 4: Quyết Định',
        url: 'https://player.vimeo.com/video/1034076476',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Quyết định của bạn được tạo nên từ...',
                answerchoice: [
                    'A. Từ SEE tốt (trải nghiệm tốt) và SEE xấu (trải nghiệm xấu).',
                    'B. Từ nhà trường và nơi làm việc.',
                    'C. Từ cách mọi người xung quanh hành động.',
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Từ trước khi sinh ra, chúng ta đã sở hữu...',
                answerchoice: [
                    'A. Bản kế hoạch cuộc đời.',
                    'B. Ký Ức.',
                    'C. Không gì cả.',
                ],
                result: 1,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Tất cả chúng ta đều ảnh hưởng từ...',
                answerchoice: [
                    'A. Những trải nghiệm trong cuộc sống.',
                    'B. Những quyết định từ người khác.',
                    'C. Những hành vi lặp đi lặp lại khi còn bé.',
                ],
                result: 0,
            },
        ],
    },
    {
        id: 4,
        title: 'Bài 5: Phản Hồi',
        url: 'https://player.vimeo.com/video/1034107432',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Quá khứ của bạn đang sống ở...',
                answerchoice: [
                    'A. ngay trong chính con người bạn.',
                    'B. đã kết thúc.',
                    "C. kí ức.",
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Người có vô số SEE xấu sẽ...',
                answerchoice: [
                    'A. Sợ hãi. Lo lắng. Thiếu quyết đoán.',
                    'B. Hạnh phúc. An toàn. Dễ chịu.',
                    'C. Không có gì diễn ra cả.',
                ],
                result: 0,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: “Cơ thể nói chuyện với bạn” còn được gọi là gì?',
                answerchoice: [
                    'A. Sự giáo dục.',
                    'B. Sự phản hồi.',
                    'C. Sự răn đe.',
                ],
                result: 1,
            },
        ],
    },
    {
        id: 5,
        title: 'Bài 6: Kiểm Soát Cảm Xúc',
        url: 'https://player.vimeo.com/video/1034107981',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Vì sao chúng ta cần học cách kiểm soát những cảm xúc tiêu cực?',
                answerchoice: [
                    'A. Để chúng ta học được cách bình tĩnh hơn.',
                    'B. Để chúng ta không mắc sai lầm.',
                    'C. Để khi những cảm xúc tiêu cực ngăn cản chúng ta làm điều mình muốn, chúng ta vẫn có thể vượt qua được và làm bằng mọi giá.',
                ],
                result: 2,
            },
            {
                id: 1,
                question: 'Câu hỏi 2:  Làm sao để vượt qua “phản hồi sợ hãi” nhận được từ cơ thể?',
                answerchoice: [
                    'A. Sử dụng trí tưởng tượng.',
                    'B. Cố gắng gạt bỏ suy nghĩ tiêu cực.',
                    'C. Tìm ai đó giúp đỡ.',
                ],
                result: 0,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Khi chúng ta bắt đầu căng thẳng và liên tục suy nghĩ trong trạng thái lo lắng, chúng ta đang sử dụng hết...',
                answerchoice: [
                    'A. Khí oxy ở não.',
                    'B. Năng lượng.',
                    'C. Sự tự tin.',
                ],
                result: 0,
            },
        ],
    },
    {
        id: 6,
        title: 'Bài 7: Vấn Đề/Kết Luận',
        url: 'https://player.vimeo.com/video/1034120808',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Vấn đề lớn nhất mà chúng ta có thể gặp phải trong cuộc đời họ là gì?',
                answerchoice: [
                    'A. Tài chính.',
                    'B. Cái chết.',
                    'C. Niềm vui.',
                ],
                result: 1,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Nguyên nhân gây ra bệnh?',
                answerchoice: [
                    'A. Tự nhiên.',
                    'B. Lối sống kém lành mạnh.',
                    'C. Sự căng thẳng (Stress).',
                ],
                result: 2,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: “Tự phản bội” là gì?',
                answerchoice: [
                    'A. Xung đột với chính bản thân.',
                    'B. Làm chuyện xấu với người khác.',
                    'C. Cả hai đều sai.',
                ],
                result: 0,
            },
        ],
    },
    {
        id: 7,
        title: 'Bài 8: Thử Thách Của Thành Công',
        url: 'https://player.vimeo.com/video/1035459457',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Nỗi đau đớn (Pain) là gì?',
                answerchoice: [
                    'A. Sự trưởng thành.',
                    'B. Sự căng thẳng.',
                    'C. Cả hai đều đúng.',
                ],
                result: 2,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: No Pain No Gain được hiểu như thế nào?',
                answerchoice: [
                    'A. Thành công không cần nỗ lực.',
                    'B. Trải qua khó khăn, thách thức để phát triển toàn vẹn.',
                    'C. Chỉ có đau đớn thể chất mới thành công.',
                ],
                result: 1,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Tư duy No Pain No Gain?',
                answerchoice: [
                    'A. “Thành công không nên được xây dựng từ sự đau khổ.”',
                    'B. “Tôi không muốn thất bại, vậy nên tôi lựa chọn không bắt đầu.”',
                    'C. “Nỗi đau đớn này là nền tảng và động lực giúp tôi phát triển.”',
                ],
                result: 2,
            },
        ],
    },
    {
        id: 8,
        title: 'Bài 9: Hình Dung Cuộc Đời',
        url: 'https://player.vimeo.com/video/1034120918',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Hình dung cuộc đời bạn như là...',
                answerchoice: [
                    'A. Big Picture (Bức tranh lớn)',
                    'B. Big Journey (Chuyến đi lớn)',
                    'C. Big Challenge (Thử thách lớn)',
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Hai câu hỏi bạn phải tự trả lời cho chính mình?',
                answerchoice: [
                    'A. Mục tiêu năm sau và Kế hoạch làm giàu.',
                    'B. Danh sách những việc cần làm và Kế hoạch dài hạn.',
                    'C. Bạn muốn trở thành ai khi bạn trưởng thành/Muốn gì ở thời điểm đó và Hình mẫu mà bạn muốn trở thành.',
                ],
                result: 2,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: “Không tìm ra được mục tiêu, không biết mình đi về đâu sẽ dẫn đến thất bại.”',
                answerchoice: [
                    'A. Đúng.',
                    'B. Sai.',
                    'C. Có thể.',
                ],
                result: 0,
            },
        ],
    },
    {
        id: 9,
        title: 'Bài 10: Dự Án Cuộc Đời',
        url: 'https://player.vimeo.com/video/1034125378',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Vì sao chúng ta cần Dự Án Cuộc Đời (Life Project)?',
                answerchoice: [
                    'A. Bởi vì chỉ có chúng ta mới hoàn thành được dự án cho cuộc đời mình.',
                    'B. Vì ai cũng như thế.',
                    'C. Vì chúng ta không muốn thua cuộc.',
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2:  Mục đích sống là gì?',
                answerchoice: [
                    'A. Làm giàu.',
                    'B. Nổi tiếng.',
                    'C. Làm cho tất cả mọi người được hạnh phúc và cảm thấy viên mãn.',
                ],
                result: 2,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Trình tự tạo nên Dự Án Cuộc Đời (Life Project)',
                answerchoice: [
                    'A. Ám ảnh - Mục đích sống - Tầm nhìn - Mục tiêu ngắn hạn - Mục tiêu dài hạn.',
                    'B. Hi vọng - Mục đích - Tầm nhìn - Thử - Thực tiễn.',
                    'C. Cả hai đều đúng.',
                ],
                result: 0,
            },
        ],
    },
    {
        id: 10,
        title: 'Trạm 1: Kỹ Năng',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Hầu hết những nhà lãnh đạo tốt đều muốn nhân viên...',
                answerchoice: [
                    'A. Hành xử lý tính.',
                    'B. Cư xử tốt giống như mình.',
                    'C. Không phạm lỗi.',
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2:  5 cảm xúc tích cực:',
                answerchoice: [
                    'A. Đồng cảm - Lòng trắc ẩn - Tình yêu - Sự hăng hái/Nhiệt huyết - Hạnh phúc.',
                    'B. Vui vẻ - Dịu dàng - Sôi nổi - Hòa đồng - Phấn khích.',
                    'C. Ngọt ngào - Đồng cảm - Hài hước - Vui vẻ - Công bằng.',
                ],
                result: 2,
                ////
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Mỗi người có bao nhiêu SEE trong cuộc đời?',
                answerchoice: [
                    'A. 1',
                    'B. 27',
                    'C. Rất nhiều',
                ],
                result: 0,
            },
            {
                id: 3,
                question: 'Câu hỏi 4:  “Người đàn ông đỏ” và “Người đàn ông xanh” tượng trưng cho điều gì?',
                answerchoice: [
                    'A. Tức giận và Vui vẻ.',
                    'B. Trải nghiệm/Hành vi xấu và Trải nghiệm/Hành vi tốt.',
                    'C. Không gì cả.',
                ],
                result: 2,
            },
        ],
    },
    {
        id: 11,
        title: 'Trạm 2: Tư Duy',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: “Phản hồi nhận được từ cơ thể” bao gồm:',
                answerchoice: [
                    'A. Cảm xúc. Tiếng nói bên trong. Ảo giác.',
                    'B. Cảm xúc. Tư duy. Hành động.',
                    'C. Cảm xúc. Tiếng nói bên trong. Hành động.',
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: Trạng thái hệ thống thần kinh thư giãn giúp chúng ta...',
                answerchoice: [
                    'A. dễ chịu.',
                    'B. ghi nhớ và lưu trữ được toàn bộ thông tin não bộ tiếp nhận được.',
                    'C. rèn luyện để bộ não luôn trong trạng thái hưng phấn và tích cực.',
                ],
                result: 1,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Bạn giao tiếp nhiều nhất với...',
                answerchoice: [
                    'A. chính mình.',
                    'B. cha mẹ.',
                    'C. bạn bè xung quanh.',
                ],
                result: 0,
            },
        ],
    },
    {
        id: 12,
        title: 'Trạm 3: Về Đích',
        question: [
            {
                id: 0,
                question: 'Câu hỏi 1: Nhà lãnh đạo thật sự là gì?',
                answerchoice: [
                    'A. Đối xử với mọi người đều công bằng như nhau, bất kể họ là ai.',
                    'B. Hành xử dứt khoát.',
                    'C. Giỏi kinh doanh.',
                ],
                result: 0,
            },
            {
                id: 1,
                question: 'Câu hỏi 2: 5 cảm xúc tiêu cực mà chúng ta cần phải kiểm soát?',
                answerchoice: [
                    'A. Kích động - Hăng hái - Buồn bã - Lo lắng - Ngạc nhiên.',
                    'B. Tức giận - Buồn bã - Sợ hãi - Ghét - Tội lỗi.',
                    'C. Tuyệt vọng - Chán nản - Khinh thường - Sợ hãi - Lo âu.',
                ],
                result: 1,
            },
            {
                id: 2,
                question: 'Câu hỏi 3: Một người từng ở trong một mối quan hệ cũ không được tốt đẹp, để lại những SEE xấu, khi chuẩn bị bước vào một mối quan hệ mới, họ cảm thấy...',
                answerchoice: [
                    'A. Vui vẻ tiếp nhận.',
                    'B. Sợ hãi và từ chối.',
                    'C. Tùy vào mức độ tình cảm thời điểm đó.',
                ],
                result: 1,
            },
            {
                id: 3,
                question: 'Câu hỏi 4: Tất cả chúng ta đều ảnh hưởng từ...',
                answerchoice: [
                    'A. Những trải nghiệm trong cuộc sống.',
                    'B. Những quyết định từ người khác.',
                    'C. Những hành vi lặp đi lặp lại khi còn bé.',
                ],
                ////
                result: 0,
            },
            {
                id: 4,
                question: 'Câu hỏi 5:   “Cơ thể nói chuyện với bạn” còn được gọi là gì?',
                answerchoice: [
                    'A. Sự giáo dục.',
                    'B. Sự phản hồi.',
                    'C. Sự răn đe.',
                ],
                result: 1,
            },
            {
                id: 5,
                question: 'Câu hỏi 6: Vì sao chúng ta cần học cách kiểm soát những cảm xúc tiêu cực?',
                answerchoice: [
                    'A. Để chúng ta học được cách bình tĩnh hơn.',
                    'B. Để chúng ta không mắc sai lầm.',
                    'C. Để khi những cảm xúc tiêu cực ngăn cản chúng ta làm điều mình muốn, chúng ta vẫn có thể vượt qua được và làm bằng mọi giá.',
                ],
                result: 2,
            },
            {
                id: 6,
                question: 'Câu hỏi 7: Trạng thái hệ thống thần kinh thư giãn giúp chúng ta...',
                answerchoice: [
                    'A. dễ chịu.',
                    'B. ghi nhớ và lưu trữ được toàn bộ thông tin não bộ tiếp nhận được.',
                    'C. rèn luyện để bộ não luôn trong trạng thái hưng phấn và tích cực.',
                ],
                result: 1,
            },
            {
                id: 7,
                question: 'Câu hỏi 8: Tư duy No Pain No Gain?',
                answerchoice: [
                    'A. “Thành công không nên được xây dựng từ sự đau khổ.”',
                    'B. “Tôi không muốn thất bại, vậy nên tôi lựa chọn không bắt đầu.”',
                    'C. “Nỗi đau đớn này là nền tảng và động lực giúp tôi phát triển.”',
                ],
                result: 2,
            },
            {
                id: 8,
                question: 'Câu hỏi 9: Hình dung cuộc đời bạn như là...',
                answerchoice: [
                    'A. Big Picture (Bức tranh lớn)',
                    'B. Big Journey (Chuyến đi lớn)',
                    'C. Big Challenge (Thử thách lớn)',
                ],
                result: 0,
            },
            {
                id: 9,
                question: 'Câu hỏi 10: Trình tự tạo nên Dự Án Cuộc Đời (Life Project)',
                answerchoice: [
                    'A. Ám ảnh - Mục đích sống - Tầm nhìn - Mục tiêu ngắn hạn - Mục tiêu dài hạn.',
                    'B. Hi vọng - Mục đích - Tầm nhìn - Thử - Thực tiễn.',
                    'C. Cả hai đều đúng.',
                ],
                result: 0,
            },
        ],
    },
];