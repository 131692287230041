export const fansipanPromoteBasic = [
    'Leo cùng chuyên gia ',
    'Lịch trình 2 ngày 1 đêm',
    'Trình độ: Cơ bản',
    'Số lượng 100 người',
]

export const fansipanPromoteAdvance = [
    'Leo cùng chuyên gia',
    'Lịch trình 6 ngày 5 đêm',
    'Trình độ: nâng cao',
    'Số lượng 50 người',
    'Học kiến thức giá trị và thấu hiểu bản thân 3 ngày tại khách sạn 5 sao cùng chuyên gia',
    'Tour Sapa tiêu chuẩn 5 sao',
    'Nghỉ tại khách sạn 5 sao',
    'Thưởng thức đồ ăn  nhà hàng 5 sao',
    'Tặng voucher và ảnh chụp lưu niệm cùng chuyên gia trị giá 5 triệu',
]